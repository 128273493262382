import React from 'react';
import { graphql } from 'gatsby';
import Flex from 'styled-flex-component';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import Link from '../components/Link';
import FacebookButton from '../components/FacebookButton';
import TwitterButton from '../components/TwitterButton';

import dr1lg from '../assets/img/dr-1-lg.png';

const BlogArticleList = ({ list = [] }) => (
  <Flex column>
    {list.map(({ id, date, title, path }, index) => (
      <Flex
        key={id}
        css={`
          height: 60px;
          margin: 5px 0;
          border-bottom: ${(list.length - 1 !== index && 'solid 1px #ccc') || 'inherit'};
        `}
      >
        <Flex center>
          <span
            css={`
              font-size: 12px;
              color: #666666;
              margin-right: 30px;
            `}
          >
            {date}
          </span>
        </Flex>
        <Flex center>
          <Link to={path}>
            <span
              css={`
                font-size: 16px;
                color: #3b4043;
                overflow-wrap: anywhere;
              `}
            >
              {title}
            </span>
          </Link>
        </Flex>
      </Flex>
    ))}
  </Flex>
);

const Blog = ({ data: { allMarkdownRemark, allFile } }) => {
  const { size } = useTrackedState();
  const blogArticles = allMarkdownRemark.edges
    .filter(
      edge => !!edge.node.frontmatter.date
    )
    .map(({ node: { id, frontmatter: { title, path } } }) => ({
      id,
      title,
      path,
    }));
  return (
    <SubLayout topTitle="院長ブログ">
      <Img src={dr1lg} />
      <P>
        <span
          css={`
            font-weight: bold;
          `}
        >
          理事長/院長
          <br />
          平畑 光一(ひらはた こういち)
        </span>
      </P>
      <BlogArticleList list={blogArticles} />
      <Flex
        justifyAround={size !== 'sm'}
        column={size === 'sm'}
        center={size === 'sm'}
        css={`
          margin: 30px 20px;
        `}
      >
        <div
          css={`
            margin-bottom: 10px;
          `}
        >
          <FacebookButton size={size} />
        </div>
        <div
          css={`
            margin-bottom: 10px;
          `}
        >
          <TwitterButton size={size} />
        </div>
      </Flex>
    </SubLayout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/blog/" } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            path
          }
        }
      }
    }
  }
`;
